<template>
  <div>
    <content-section :spacing="false">
      <div class="px-2 content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus title="Trip Management" :hide-plus="true" />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>
        <div
          class="flex flex-col mt-4 mb-10 space-y-4 md:space-y-0 md:items-center md:flex-row"
        >
          <card-tax-stat
            title="Total Trips"
            :value="indexMetaData.summary.total"
            variant="yellow"
            class="mr-3"
          />
          <card-tax-stat
            title="On Rent"
            :value="indexMetaData.summary.on_trip"
            variant="green"
            class="mr-3"
          />
          <card-tax-stat
            title="Trip Completed"
            :value="indexMetaData.summary.completed"
            variant="light-blue"
            class="mr-3"
          />
          <card-tax-stat
            title="Trip Cancelled"
            :value="indexMetaData.summary.cancelled"
            variant="red"
            class=""
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="flex-auto md:flex">
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end">
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="px-2 vld-parent">
        <loading :active="isLoading" :is-full-page="false" />

        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in tableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <router-link
                class="font-bold text-blue-600 capitalize"
                :to="{
                  name: 'ViewTrip',
                  params: { id: item.id },
                }"
              >
                #{{ item.id | onlyLastFive }}
              </router-link>
            </fsTableRowItem>

            <fsTableRowItem>
              <template v-if="item.user">
                <router-link
                  class="font-bold text-blue-600 capitalize"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.user.id },
                  }"
                >
                  {{ item.user.full_name }}
                </router-link>
              </template>
              <template v-else> -- </template>
            </fsTableRowItem>

            <fsTableRowItem>
              <template v-if="item.bike">
                <router-link
                  class="font-bold text-blue-600 capitalize"
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: item.bike.id },
                  }"
                >
                  #{{ item.bike.qr_code }}
                </router-link>
              </template>
              <template v-else> -- </template>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.pick_up_time ? $localTime(item.pick_up_time) : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.pick_up_location ? item.pick_up_location : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.drop_off_location ? item.drop_off_location : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.drop_off_time ? $localTime(item.drop_off_time) : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.trip_duration ? item.trip_duration : '--' }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ symbol }}{{ parseFloat(item.total_cost).toFixed(2) }}
            </fsTableRowItem>

            <fsTableRowItem>
              <x-status
                :text="getUserTripStatus(item.status)"
                :variant="getUserTripStatusBadge(item.status)"
                profile="trip"
              />
            </fsTableRowItem>

            <fsTableRowItem>
              <div class="flex items-center">
                <oto-eye-icon
                  v-tooltip.bottom="'View Invoice'"
                  class=""
                  @click="openModal(item.id)"
                />
                <!-- <oto-edit-icon @click="edit(item)" /> -->
                <MoreActionsDropdown
                  v-if="
                    (item.status == 'U') |
                      (item.status == 'O') |
                      (item.status == 'R')
                  "
                  :currentVal="getActionOptions(item.status)"
                  :actionTripEndpoint="getActionsEndpoint(item.id)"
                  actionTripEnabled="end"
                  actionTripCancelKey="cancel"
                  actionTripEndKey="end"
                  actionTripCancelText="Cancel"
                  actionTripEndText="End"
                  actionTripCancelVal="cancel"
                  actionTripEndVal="end"
                  @actionTripCancel="getIndexData(getQueryString)"
                  @actionTripEnd="getIndexData(getQueryString)"
                />
              </div>
            </fsTableRowItem>
          </fsTableRow>
        </fsTable>

        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </content-section>

    <router-view />

    <invoice-Modal
      v-if="showModal"
      :showModal="showModal"
      @closeModal="closeModal"
      :trip="invoiceDetails"
      :symbol="this.symbol"
    />
  </div>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEyeIcon from '@/components/ui/OtoEyeIcon'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { TripConfig } from '@/config/TripConfig'

// import { EventBus } from "@/utils/EventBus";
import CardTaxStat from '@/components/cards/TaxStat'
import OtoSearch from '@/components/ui/OtoSearch'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import XStatus from '@/components/badge/XStatus'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'

import { mapGetters } from 'vuex'

// Data Model
// import TripFilter from '@/services/TripFilter'
// import { tripData } from '@/views/trip/tripData'

export default {
  name: 'Trips',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
    // tripData,
  ],
  components: {
    ContentSection,
    TitlePlus,
    OtoEyeIcon,
    InvoiceModal: () => import('@/components/modals/InvoiceModal'),

    OtoSearch,
    DateRangePicker,
    CardTaxStat,
    XStatus,
    ExportAsDropdown,
    MoreActionsDropdown,
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
  },
  data() {
    return {
      showModal: false,
      invoiceDetails: {},
      // dataModel: new TripFilter(this.$http),
      indexMetaData: {
        summary: {
          total: 0,
          on_trip: 0,
          completed: 0,
          cancelled: 0,
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: TripConfig.api.index,
      queryStringOptions: {
        prepend: '?',
        append: `&franchise_user=${this.$route.params.id}`,
      },
      tableHeaders: [
        {
          name: 'Trip ID',
          width: '9%',
          field: 'id',
          sort: null,
          order: '',
        },
        {
          name: 'Rider',
          width: '9%',
          field: 'user',
          sort: null,
          order: '',
        },
        {
          name: 'Vehicle QR',
          width: '9%',
          field: 'bike',
          sort: null,
          order: '',
        },

        {
          name: 'Pick Up Time',
          width: '13%',
          field: 'pick_up_time',
          sort: true,
          order: '',
        },
        {
          name: 'Pick Up Location',
          width: '18%',
          field: 'pick_up_location',
          sort: true,
          order: '',
        },
        {
          name: 'Drop Off Location',
          width: '18%',
          field: 'drop_off_location',
          sort: true,
          order: '',
        },
        {
          name: 'Drop Off Time',
          width: '16%',
          field: 'drop_off_time',
          sort: true,
          order: '',
        },
        {
          name: 'Duration',
          width: '5%',
          field: 'duration',
          sort: true,
          order: '',
        },
        {
          name: 'Charge',
          width: '5%',
          field: 'cost',
          sort: true,
          order: '',
        },
        {
          name: 'Status',
          width: '5%',
          field: 'status',
          sort: null,
          order: '',
        },
      ],
      symbol: '$',
      zoom: 100,
      center: {
        lat: 10,
        lng: 10,
      },
      origin: null,
      destination: null,
    }
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    window.addEventListener(TripConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    async openModal(id) {
      await this.$http.get(TripConfig.api.single(id)).then((res) => {
        this.showModal = true
        this.invoiceDetails = res.data
      })
    },
    getDirection() {
      var directionsService = new this.google.maps.DirectionsService()
      var directionsDisplay = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      })

      var vm = this

      directionsService.route(
        {
          origin: this.getLatLng(this.trip.src_coord),
          destination: this.getLatLng(this.trip.dest_coord),
          travelMode: 'DRIVING',
        },
        function(response, status) {
          if (status === 'OK') {
            directionsDisplay.setDirections(response)
            var leg = response.routes[0].legs[0]
            vm.makeMarker(leg.start_location, 'start', 'title')
            vm.makeMarker(leg.end_location, 'end', 'title')
          } else {
            vm.makeMarker(response.request.origin.location, 'start', 'title')
            //window.alert('Directions request failed due to ' + status);
          }
        }
      )
    },
    makeMarker(position, icon, title) {
      new this.google.maps.Marker({
        position: position,
        map: this.$refs.map.$mapObject,
        icon:
          icon == 'start'
            ? {
                url: require(`@/assets/img/start_place.png`),
                scaledSize: { width: 63, height: 73 },
              }
            : {
                url: require(`@/assets/img/end_place.png`),
                scaledSize: { width: 63, height: 73 },
              },
        title: title,
      })
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = parseFloat(position.split(',')[0])
      let lng = parseFloat(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    closeModal() {
      this.showModal = false
    },
    getDuration(duration) {
      if (!duration) {
        duration = '0 minutes'
      }
      let str = duration.toString()
      return str.replace('minutes', 'm')
    },
    getActionOptions(status) {
      console.log(status)
      return {
        // cancel: "Cancel",
        // end: "End"
      }
      // return status in statuses ? statuses[status] : "-";
    },
    getUserTripStatus: function(status) {
      let statuses = {
        R: 'Reserved',
        A: 'Cancelled',
        U: 'Starting',
        O: 'On Rent',
        V: 'Forced',
        C: 'Completed',
        T: 'Time Out',
      }
      return status in statuses ? statuses[status] : '-'
    },
    getUserTripStatusBadge: function(status) {
      let badges = {
        R: 'blue',
        A: 'red',
        U: 'yellow',
        O: 'purple',
        V: 'red',
        C: 'green',
        T: 'red',
      }
      let s = status in badges ? badges[status] : 'light-blue'

      return s
    },
    edit: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.editingData, item);
      // dispatchEvent(new Event(SubscriptionConfig.events.sorToggle));
    },
    view: function(item) {
      console.log('item = ', item)
      this.$router.push(`/user-management/${item.trip_short_id}`)
      //return http://localhost:8080/user-management
      // EventBus.$emit(SubscriptionConfig.events.viewingData, item);
      // this.$modal.show(SubscriptionConfig.events.viewingData);
    },
    getActionsEndpoint(id) {
      return TripConfig.api.update(id)
    },
  },
}
</script>

<style lang="scss">
@media only screen and (max-width: 500px) {
  .filteroptions {
    margin-top: 10px;
    margin-left: 0 !important;
  }
}
</style>
